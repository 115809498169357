import React, { useCallback } from "react";
import { Button, Divider, H5, H6, Intent, Popover, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import logo from "../../assets/images/csd-logo.svg";
import { getCustomerInfo } from "../../services/authService/storage";
import useLocale from '../shared/useLocale';


const CustomerInfo: React.FC = () => {
    var customer = getCustomerInfo();
    if (!customer) {
        // Should not happen
        return null;
    }

    const { customerNumber, name, name2, street, city, region, postalCode } = customer;
    
    return (
        <div style={{padding: 20, backgroundColor: "#fff"}}>
            <H6 title={`Customer ID: ${customerNumber}`}>{name}</H6>
            {name2 && <H6>{name2}</H6>}
            <div>{street}</div>
            <div>{city}</div>
            <div>{region}, {postalCode}</div>            
        </div>
    );
}


const UserMenu: React.FC = () => {
    const { locale } = useLocale();

    const logout = useCallback(() => {
        sessionStorage.clear();
        window.location.replace(window.location.origin + "/" + locale);
    }, [locale]);

    return (
        <Popover>
            <Icon icon={IconNames.USER} intent={Intent.SUCCESS} style={{cursor: "pointer"}} />
            <div style={{minWidth: 200, display: 'flex', flexDirection: "column", backgroundColor: "#fafafa"}}>
                <CustomerInfo />
                <Divider  style={{margin: 0}} />
                <Button 
                    text="Logout" 
                    small={true} 
                    minimal={true}
                    icon={IconNames.LOG_OUT} 
                    intent={Intent.NONE} 
                    style={{margin: 10, width: 100, alignSelf: "center"}}   
                    onClick={logout} 
                />
            </div>                    
        </Popover>
    );
}

export const Header: React.FC = () => {    
    return (
        <div style={{marginBottom: 30, display: "flex", alignItems: "space-between", width: "100%"}}>
            <div style={{flex: 1}}>
                <img src={logo} alt="CSD Logo" style={{width: 240}} />
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <H5 className="csd-gray" style={{flex: 1, textAlign: "right" }}>Dental Practice Management<br/>Software Download Service</H5>
                <UserMenu />
            </div>            
        </div>
    );
}


export default Header;