import { IToastProps, Intent, Position, Toaster } from "@blueprintjs/core";

// // Manages single `Toaster` instance -- created on demand.
// const toasterFactory = (function() {
//   let _toaster: IToaster | undefined = undefined;

//   function getInstance() {
//       if (!_toaster) {
//           console.info("Creating global toaster instance.")
//           _toaster = Toaster.create({position: Position.BOTTOM});
//       }
//       return _toaster;
//   }

//   return {
//     get Instance() { return getInstance() }
//   }
// })();

// // Important: `Toaster.create()` can't be called from within a React 16 component lifecycle method.
// // So, we need a way to make force creation of the toaster singleton.
// export function ensureCreated() {
//   return toasterFactory.Instance;
// }


const toasterInstance = Toaster.create({position: Position.TOP_RIGHT});

export function show(props: IToastProps, key?: string) {
  return toasterInstance.show(props, key);
}

export function showToast(content: React.ReactNode, intent: Intent = Intent.PRIMARY, timeout = 3000, key?: string) {
  const props: IToastProps = {
      message: content,
      intent,
      timeout
  };

  return show(props, key);
}


export function showFormValidationWarning() {
    return showToast("One or more fields have validation errors", Intent.DANGER, 3000, "form-validation-Warning");
}

export function dismissToast(key: string) {
  toasterInstance.dismiss(key);
}

export function dismissAllToasts() {
  toasterInstance.clear();
}
