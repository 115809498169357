import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { getAuthToken } from "../../services/authService/storage";


export enum ContentTypes  {
  MultipartFormData = 'multipart/form-data'
}

interface IServerApiInnerError {
  type: string;
  message: string;
  stacktrace: string;
  innerError?: IServerApiInnerError;
}

export interface IServerApiError {
  error: {
    code: string;
    message: string;
    innererror?: IServerApiInnerError;
  }
}

function isServerApiError(error: any): error is IServerApiError {
  return error.error && error.error.code && error.error.message;
}


// This is returned, for example, when the WebApi fails to parse the JSON (ex. invalid Guid)
export interface IServerException {
  Message: string;
  ExceptionType: string;
  ExceptionMessage: string;
}


function isServerException(error: any): error is IServerException {
  return error.Message && error.ExceptionType && error.ExceptionMessage;
}


// function isAxiosError<T = any>(error: any): error is AxiosError<T> {
//   return error && error.isAxiosError && error.config;
// }


export class HttpError extends Error {
  readonly statusCode: number | undefined;

  constructor(message: string, statusCode?: number) {
    super(message);
    this.name = "XHttpError" + (statusCode ? `.${statusCode}` : '');
    this.statusCode = statusCode;
  }
}

export class AuthError extends HttpError {
  constructor(message: string) {
    super(message, 401);
  }
}


// Create custom instance for API reqests
const http = axios.create();



async function requestInterceptor(config: AxiosRequestConfig) {
  if (!config.method) {
    throw new Error("HTTP Rquest invoked witout a method.")
  }

  if (!config.headers.Authorization)
  {
    // OAuth Bearer token
    var token = getAuthToken();
    if (token)
    {
      config.headers.Authorization = `Bearer ${token.accessToken}`;
    }  
  }

  // Used by backend to filter available document list based on locale
  const locale = sessionStorage.getItem("locale");
  if (!!locale) {
    config.headers["X-ClientLocale"] = locale;
  }

  return config;
};




function throwHttpError(error: AxiosError<any>, isRetry: boolean): never {
  const statusCode = error.response && error.response.status;
  console.assert(statusCode !== 401);
  const prefix = isRetry ? "[Retry-Phase] " : "";

  // Handle "400 - Bad Data" error.
  if (statusCode === 400) {
    const errorResponse = error.response!.data;

    if (isServerException(errorResponse)) {
      throw new HttpError(prefix + errorResponse.ExceptionMessage, statusCode);
    }

    if (isServerApiError(errorResponse)) {
      throw new HttpError(prefix + errorResponse.error.message, statusCode);
    }

    if (errorResponse.Message) {
      throw new HttpError(prefix + errorResponse.Message, statusCode);
    }

    throw new HttpError(prefix + errorResponse, statusCode);
  }

  throw new HttpError(prefix + error.message, statusCode);
}




function errorResponseInterceptor(error: AxiosError<any>) {

  console.error("HTTP Failure: ", error);
  // NOTE: When a CORS request fails, no response is passed along to Axios, and Axios will return
  // `error.code === undefined`, `error.response === undefined` and `error.message` === "Network Error".
  // Ref: https://github.com/axios/axios/issues/383
  const statusCode = error.response && error.response.status;
  if (!statusCode) {
    throw new HttpError(typeof error.code === 'undefined' ?  "Endpoint unreachable, or possible CORS failure." : error.message, statusCode);
  }

  // Is this an authentication error?
  if (statusCode === 401) {
    throw new AuthError("Authentication failure: " + error.message);
  }

  throwHttpError(error, false);
};




http.interceptors.request.use(requestInterceptor);
http.interceptors.response.use(undefined, errorResponseInterceptor);
export default http;

