import React, { useCallback } from "react";
import { Icon, H4, Intent, AnchorButton } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { LogItemType, IFileItem } from "../../services/dataService/models"
import { saveLogItem } from "../../services/dataService";
import { markdownToHtml } from "../../utils/markdownUtils";


function formatFileSize(size: number) {
    if (!size) return "";
  
    const i = Math.floor(Math.log(size) / Math.log(1024));
  
    return (
      `${(size / Math.pow(1024, i < 0 ? 0 : i)).toFixed(2)} ${
        ["B", "KB", "MB", "GB", "TB"][i < 0 ? 0 : i]
      }` || ""
    );
  }
  


const File: React.FC<IFileItem> = ({ id, fileName, title, description, downloadInfo }) => {    

    const onDownloadLinkClicked = useCallback(() => {
        // Don't wait for completion
        saveLogItem({
            type: LogItemType.DownloadInitiated,
            message: "File download initialed.",
            data: { id, title, fileName }
        });
        // Note: Don't prevent default
    }, [fileName, id, title]);

    
    return (
        <div className="file">
            <div style={{flex: "1", paddingRight: "1em"}}>
                <div><H4 title={id}>{title}</H4></div>
                <div dangerouslySetInnerHTML={{__html: markdownToHtml(description)}} />               
            </div>

            <div className="centered download">
                <div title={fileName} className="centered" style={{flexDirection: "column", height: "auto" }} >
                    <Icon icon={IconNames.ARCHIVE} intent={Intent.PRIMARY} />
                    <div style={{marginTop: 6}}>                    
                        {formatFileSize(downloadInfo.fileSize)}
                    </div>
                </div>
                <div style={{margin: "6px 0"}}>
                    <AnchorButton intent={Intent.PRIMARY} small={true}  onClick={onDownloadLinkClicked} title="Download File" href={downloadInfo.url} text="Download" />
                </div>
            </div>
        </div>
    );
}


export default File;
