import {  getBrowserLocale, normalizeLocaleString } from "../shared/useLocale";

const supportedLocales = /en-US|en-GB|en-CA/;

export function getFallbackLocale(locale = getBrowserLocale()) {    
    const normalizedLocale = normalizeLocaleString(locale);
    // Note: It's possible that the locale is supported, but the case is incorrect.
    if (locale !== normalizedLocale && supportedLocales.test(normalizedLocale)) {
        console.log(`%cLocale match (case-insesitive): ${locale} --> ${normalizedLocale}`, "color:orange");        
        return normalizedLocale;
    }
    
    // Not a suppoted locale.  Use fallback logic.    
    let localeOverride = "en-US";
    const localeRegion = normalizedLocale.slice(-2);
    switch (localeRegion) {
        case "GB":  localeOverride = "en-GB"; break;
        case "CA":  localeOverride = "en-CA"; break;
    }    

    if (localeOverride !== normalizedLocale) {
        console.log(`%cUsing locale override: ${locale} --> ${localeOverride}`, "color:orange");
    }
    
    return localeOverride;    
}

const localeRouteBasePath = `/:locale(${supportedLocales.source})`;

const routePaths = {    
    fallbackLocale: `/${getFallbackLocale()}`,
    unknownLocale: `/:locale([a-zA-Z]{2}-[a-zA-Z]{2})`,

    locale: localeRouteBasePath,                
    home: localeRouteBasePath +"/home",    
    files: localeRouteBasePath + "/files"    
}


export default routePaths;