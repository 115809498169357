import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { AppConfigProvider } from "../shared/useAppConfig";
import AppErrorBoundary from "./AppErrorBoundary";
import AppRoutes from "./Routes";



const App: React.FC = () => {  
  return (
    <div className="app">
      <AppErrorBoundary>
        <AppConfigProvider>          
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>          
        </AppConfigProvider>
      </AppErrorBoundary>
    </div>
  );
}


export default App;
