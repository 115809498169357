import React, { useCallback, useReducer } from "react";
import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import useLocale from "../../shared/useLocale";
import TextField, { ITextFieldState } from "./TextField";



const sapIdRegex = /^\d{6,10}$/;

function getCustomerIdErrorMessage(value: string) {
    if (!value) {
        return "Required field";
    }
    
    if (!sapIdRegex.test(value)) {
        return "6 to 10 digits only";
    } 
}


const textFieldReducer = (preState: ITextFieldState, nextState: ITextFieldState) => nextState;


interface IProps {
    handleLogin: (customerId: string, postCode: string) => Promise<any>;
}


const Form: React.FC<IProps> = ({handleLogin}) => {
    const { isUsLocale, settings } = useLocale();
    //const custIdLabel = isUsLocale ? "Customer ID" : "Customer Number";
    const custIdLabel = "Customer ID";
    const postCodeLabel = isUsLocale  ? "ZIP Code" : "Post Code";
    const [customerIdField, handleCustomerIdChange ] = useReducer(textFieldReducer, { value: "", isValid: false});
    const [postCodeField, handlePostCodeChange ] = useReducer(textFieldReducer, { value: "", isValid: false});
    const isValid = customerIdField.isValid && postCodeField.isValid;

    const getPostCodeErrorMessage = useCallback((value: string) => {
        if (!value) {
            return "Required field";
        }
        
        if (!settings.postCode.regex.test(value)) {
            return settings.postCode.desc;
        }
    }, [settings.postCode]);

    const onButtonClick = useCallback(() => {
        console.assert(customerIdField.isValid && postCodeField.isValid);
        handleLogin(customerIdField.value, postCodeField.value);
    }, [customerIdField.isValid, customerIdField.value, handleLogin, postCodeField.isValid, postCodeField.value]);
    
    
    return (
        <div className="hcentered" style={{flexDirection: "column"}}>
            <div className="hcentered" style={{}}>
                <div style={{marginRight: 20}}>
                    <TextField 
                        name="customerId" 
                        label={custIdLabel} 
                        initialValue={customerIdField.value} 
                        onChange={handleCustomerIdChange} 
                        getErrorMessage={getCustomerIdErrorMessage}
                    />
                </div>
                <div>
                    <TextField 
                        name="postCode" 
                        label={postCodeLabel}
                        initialValue={postCodeField.value} 
                        forceUppercase={true}
                        onChange={handlePostCodeChange} 
                        getErrorMessage={getPostCodeErrorMessage}
                    />
                </div>
            </div>
            
            <div className="hcentered">
                <Button 
                    text="Submit" 
                    icon={IconNames.LOG_IN} 
                    intent={Intent.SUCCESS} 
                    disabled={!isValid} 
                    style={{minWidth: 160, marginTop: 40}}
                    onClick={onButtonClick}
                />
            </div>            
        </div>
    );
}

export default Form;