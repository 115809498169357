import React, { useCallback, useState, useEffect } from "react";
import { Redirect } from "react-router";
import { Icon, H4, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import logo from "../../assets/images/csd-logo.svg";
import { AuthError } from "../../services/api/http";
import { login, isLoggedIn } from "../../services/authService";
import { startAppWarmUp } from "../../services/dataService";
import useLocale from "../shared/useLocale";
import ModalWait from "../shared/ModalWait";
import { showToast } from "../../services/ToasterService";
import routePaths from "../routing";
import Form from "./Form";
import Intro from './Intro';


const Home: React.FC = () => {
    const { buildLocaleRoute } =  useLocale();
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => { startAppWarmUp(); }, []);
    
    
    const handleLogin = useCallback(async (customerId: string, postCode :string) => {
        setIsBusy(true);
        try {
            await login(customerId, postCode);            
        } catch (error) {
            const msg = error instanceof AuthError 
                ? "The information you provided didn't match our records." 
                : <div>Sorry, there was an error verifying your information.<br/>Please try again later.</div>;            
            showToast(msg, Intent.DANGER, 5000);
        }
        finally {
            setIsBusy(false);
        }        
    }, []);

    if (isLoggedIn()) {        
        return <Redirect to={buildLocaleRoute(routePaths.files)} />
    }    

    return (                           
        <div style={{width: 800, minWidth: 620, flexDirection: "column"}}>
            <ModalWait isOpen={isBusy} rightOrBottomText="Please Wait"  />                     
            
            <div className="hcentered">
                <img src={logo} alt="CSD Logo" style={{width: 300}} />
            </div>
            
            <div className="centered csd-flow" style={{borderRadius: 8, padding: 20, margin: "30px 0", flexDirection: "column" }}>                
                <div className="centered">
                    <Icon icon={IconNames.CLOUD} iconSize={80} className="csd-blue" />
                    <Icon icon={IconNames.ARROW_RIGHT} iconSize={40} className="csd-gray" style={{margin: "0 30px"}} />
                    <Icon icon={IconNames.APPLICATIONS} iconSize={60} className="csd-blue"  />
                </div>
            
                <H4 className="csd-gray">Dental Practice Management Software Download Service</H4>
            </div> 

            <div style={{flexDirection: "column", padding: 40}}>
                <Intro />
            </div>
            
            <Form handleLogin={handleLogin} />                
        </div>
        
    );
}


export default Home;

