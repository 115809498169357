import React from 'react';
import ReactDOM from "react-dom";
import './index.scss';
import App from './components/App';
import * as serviceWorker from './serviceWorker';


if (!__csd__.isSupportedBrowser) {
  console.warn("Unsupported browser: ", navigator.userAgent) ;
}
else
  ReactDOM.render(
    // Turn off StrictMode for now, since current version of BluePrint JS (v3.28.1) triggers a bunch of warnings/errors.
    //<React.StrictMode>
      <App />
    //</React.StrictMode>,
    ,
    document.getElementById('root')
  );


serviceWorker.unregister();
