import * as xss from "xss";
import * as showdown from "showdown";


const markdownOptions: showdown.ShowdownOptions = {
    noHeaderId: true,
    underline: true,
    strikethrough: true,
    tables: true,
    emoji: true,
    parseImgDimensions: true,
    simplifiedAutoLink: true,
  };
  
  
  const stylingWhitelist = ["style", "class"];
  
  const xssOptions: xss.IFilterXSSOptions = {
    whiteList: {
      ...xss.getDefaultWhiteList(),
      span: stylingWhitelist,
      div: stylingWhitelist
    }
  };


  const converter = new showdown.Converter(markdownOptions);

  export function markdownToHtml(content: string[]) {
    const unsafeHtml = converter.makeHtml(content.join("\n"));
    const saferHtml = xss.filterXSS(unsafeHtml, xssOptions);
    return saferHtml;        
  }