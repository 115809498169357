

export interface IDbItem 
{
    id: string;
    unixTimestamp: number;
}

export interface IDownloadInfo {
    url: string;
    fileSize: number;
}

export interface IFileItem extends IDbItem
{
    fileName: string;
    title: string;
    description: string[];
    downloadInfo: IDownloadInfo;
}


export enum LogItemType
{
    None,
    Info,
    LoginSuccess,
    LoginFailure,
    DownloadInitiated,
    Error = 99
}


export interface ILogItem extends IDbItem {
    customerId: string;
    type: LogItemType;
    message: string;
    data?: {[key: string]: any }
}

export type PostedLogItem = Omit<ILogItem, "id" | "unixTimestamp" | "customerId">;


