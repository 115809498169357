import http from "../api/http";
import { loadConfig } from "../appConfigService";
import { IFileItem, ILogItem, PostedLogItem } from "./models";


function formatElapsedTime(startTicks: number) {
    const elapsed = Date.now() - startTicks;
    const seconds = elapsed / 1000;
    return seconds.toFixed(2) + "s";

}

export async function startAppWarmUp()
{
    const { apiUrl } = await loadConfig();
    const startTime = Date.now();
    try {
        const { data } = await http.get(apiUrl + "warmup");
        console.log(`API Warmup: ${formatElapsedTime(startTime)}.`, data);
    } catch (error) {
        console.error(`API Warmup: ${formatElapsedTime(startTime)}.`, error.message);
    }
}


export async function getFileList()
{
    const { apiUrl } = await loadConfig();
    const { data } = await http.get<IFileItem[]>(apiUrl + "downloads");    
    return data;    
}


export async function saveLogItem(item: PostedLogItem) {
    const { apiUrl } = await loadConfig();
    
    try {
        const { data } = await http.post<ILogItem[]>(apiUrl + "logs", item);
        return data;    
    } catch (error) {
        console.error("Failed to save to log! ", item);
        return null;
    }        
}