import React from "react";
import { Callout, Intent, Icon, Classes } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";


const Comp: React.FC = () =>  {
    return (
        <Callout className={`instructions ${Classes.RUNNING_TEXT}`} title="Instructions" icon={null} intent={Intent.PRIMARY}>            
            <div>
                <Icon icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} />&nbsp;
                Before downloading software, review the System Requirements and read all software release documentation.
            </div>                

            <div>
                To download and install an update: 
                <ol style={{marginTop: 0}}>
                    <li>Click <code>Download</code> next to the update.</li>
                    <li>Save the file on your PC in the default download location.</li>
                    <li>
                        When you are ready to install the update, open the default download folder and locate the downloaded files.
                        <br/>Do one of the following:
                        <div style={{marginLeft: '2em'}}>
                            <div>If you downloaded an .exe file, double-click the downloaded application.</div>
                            <div>OR</div>
                            <div>If you downloaded a zipped file, right-click the file and select Extract All. 
                            Once the files are extracted, open the folder and double-click the files to open.</div>
                        </div>
                    </li>
                </ol>
            </div>
            
            {/* <div>
                <Icon icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} />&nbsp;
                Always download the update.  Do not attempt to run the update from this location.
            </div> */}

        </Callout>
    );
}



/*
const CompOld: React.FC = () =>  {
    return (
        <Callout className={`instructions ${Classes.RUNNING_TEXT}`} title="Instructions" icon={null} intent={Intent.PRIMARY}>
            <ul>
                <li>
                    <Icon icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} />&nbsp;
                    Before downloading software, review the system requirements and read all sotware release documentation.
                </li>                

                <li>
                    To download and install an update: 
                    <ol style={{marginTop: 0}}>
                        <li>
                            Click Download next to the update.&nbsp;
                            <strong>Note:</strong> Always download the update.  Do not attempt to run the update from this location.
                        </li>
                        <li>Save the file on your PC in the default download location. </li>
                        <li>When you are ready to install the update, open the default download folder and double-click the downloaded file.</li>
                    </ol>
                </li>

                <li>
                    Some of the file downloads use ZIP format.   
                    Once you download a file, go to the <code>Downloads</code> folder on your computer to find the downloaded file.
                    Right-click the file and select the <code>Extract All</code> menu option. You can then 
                    access the files from the download by going into the folder where you extracted the files.
                </li>

                <li>                    
                    Please note that when you download some of the files listed below, your browser may alert you to the potential 
                    danger of running unknown installation files.  Carstream Dental implements rigorous processes to ensure our downloads are free
                    from viruses and/or other malware. As such, when alerted by your browser, please select the option to continue. 
                    The specifc action required to continue the download and installation varies from browser to browser. 
                    In general, look for an option that says: <code>Keep</code>, <code>Continue</code>, <code>Save</code> etc.
                </li>
            </ul>

        </Callout>
    );
}
*/


export default Comp;