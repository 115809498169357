import http from "../api/http";
import { loadConfig } from "../appConfigService";
import { getAuthToken, saveAuthToken, clearAuthToken, saveCustomerInfo } from "./storage";
import { IAuthResponse } from "./models";


export function isLoggedIn()
{
    const token = getAuthToken();    
    return !!token;
}

export async function login(customerId: string, postCode: string)
{
    console.assert(!!customerId);
    console.assert(!!postCode);

    clearAuthToken();
    const { apiUrl } = await loadConfig();
    const { data } = await http.post<IAuthResponse>(apiUrl + "auth/login", { CustomerId: customerId, PostCode: postCode });
    console.assert(!!data.accessToken);
    const expiresOn = new Date(new Date(data.issuedAt).valueOf() + data.expiresIn * 1000);
    saveAuthToken({accessToken:  data.accessToken, expiresOn});
    saveCustomerInfo(data.customer);

    return data;    
}


export function logout()
{
    sessionStorage.clear();
    window.location.replace(window.location.origin);
}