import React from "react";
import { Route, RouteProps } from "react-router";
import { isLoggedIn } from "../../services/authService";
import LoginRequired from "./LoginRequired";

interface IProps extends RouteProps {
}


const PrivateRoute: React.FC<IProps> = (props) => isLoggedIn() 
  ? <Route {...props} />
  : <LoginRequired />

export default PrivateRoute;
