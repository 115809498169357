import { IAuthData, ICustomer } from "./models";

const authKey = "auth";
const custKey = "cust";


export function saveAuthToken(token: IAuthData)
{
    sessionStorage.setItem(authKey, JSON.stringify(token));
}

export function getAuthToken()
{    
    var storedData = JSON.parse(sessionStorage.getItem(authKey) || "null") as IAuthData;
    if (!storedData || !storedData.accessToken) {
        return null;
    }

    const data: IAuthData = {
        accessToken: storedData.accessToken,
        expiresOn: new Date(storedData.expiresOn),
    }

    if (data.expiresOn.valueOf() < Date.now()) {
        sessionStorage.removeItem(authKey);
        return null;
    }

    return data;
}


export function clearAuthToken(){
    sessionStorage.removeItem(authKey);
}


export function saveCustomerInfo(customer: ICustomer) {
    sessionStorage.setItem(custKey, JSON.stringify(customer));
}


export function getCustomerInfo() {
    const customer = JSON.parse(sessionStorage.getItem(custKey) || "null") as ICustomer;
    return customer;
}


