import axios from "axios";
import UrlAssembler from "url-assembler";


// export interface IAzureAdConfig {
//   readonly tenantId: string;
//   readonly clientId: string;
//   readonly roles: { user: string, admin: string; }
// }

export interface IAppConfig {
  readonly name: string;
  readonly apiUrl: UrlAssembler;
  readonly oDataUrl: UrlAssembler;
  readonly buildVersion: string;
}

type IAppConfigJson = {
  name: string;
  dataUrl: string;
  autoSetDataUrlInProd: boolean;
  buildVersion: string;
};

export class AppConfig implements IAppConfig {
  readonly name: string;
  //private readonly dataUrl: UrlAssembler;
  readonly apiUrl: UrlAssembler;
  readonly oDataUrl: UrlAssembler;
  readonly buildVersion: string;

  
  constructor(json: IAppConfigJson) {
    
    if (json.autoSetDataUrlInProd && process.env.NODE_ENV === "production") 
    {
      console.log("Using self as API endpoint");
      json.dataUrl = window.location.origin + "/v1.0/";
      console.log("Auto setting Data-URL: " + json.dataUrl);
    }

    console.assert(json.name && json.dataUrl && json.buildVersion);
    this.name = json.name;

    const dataUrl = new UrlAssembler(json.dataUrl);
    this.apiUrl = dataUrl.segment("api/");
    this.oDataUrl = dataUrl.segment("odata/");

    this.buildVersion = json.buildVersion;
  }
}

let loadedConfig: IAppConfig;

// Load config file from "/public/config.json"
const configFileName = "config.json";
const configUrl = process.env.PUBLIC_URL + "/" + configFileName;

async function internalLoadConfig() {
  try {
    const configResponse = await axios.get<IAppConfigJson>(configUrl);
    loadedConfig = new AppConfig(configResponse.data);
    return loadedConfig;
  }
  catch (e) {
      throw new Error(`Failed to load app configuration (${configFileName})`);
  }
}


const configLoaderPromise = internalLoadConfig();
// The following isn't strictly needed -- we could just export `cachedConfigLoader` directly.
// However, making it a function makes for a better API. Ex: Instead of `loadConfig.then(...)`, caller will need to use `loadConfig().then(...)`
const loadConfig = () => configLoaderPromise;

function getLoadedConfig() {
  if (!loadedConfig) {
    throw new Error("App COnfiguration hasn't been loaded!")
  }

  return loadedConfig;
}

export { getLoadedConfig as default, loadConfig };




