import React, { useEffect, useState } from "react";
import usePromiseState from "../shared/usePromiseState";
import { getFileList } from "../../services/dataService";
import PageHeader from "./PageHeader";
import Content from "./Content";


const Files: React.FC = () => {    
    const [promise, setPromise] = useState<ReturnType<typeof getFileList>>();
    const [, result, error] = usePromiseState(promise);

    useEffect(() => {
        setPromise(getFileList());
    }, []);
    
    return (
        <div className="hcentered" style={{flexDirection: "column", width: 800}} >
            <PageHeader />
            <Content files={result} error={error} />
        </div>
    );
}


export default Files;

