import React, { memo } from "react";
import { Callout, Intent, ProgressBar} from "@blueprintjs/core";
import { IFileItem } from "../../services/dataService/models"
import Instrucstions from "./Instructions";
import File from "./File";



const Content: React.FC <{files?: IFileItem[], error?: Error}>= memo(({files, error}) => {
    if (error) {
        return (
            <Callout title="Error" intent={Intent.DANGER}>
                Sorry, there was an error while trying to retrieve the list for file downloads
            </Callout>
        );
    }

    if (!files) // still loading
    {
        return (
            <div className="centered" style={{ marginTop: 200, flexDirection: "column" }}>
                <div style={{margin: "10px 0"}}>Loading File List</div>
                <div style={{width: 240}}>
                    <ProgressBar intent={Intent.PRIMARY} />
                </div>
            </div>
        );
    }

    if (files.length === 0) {
        return (
            <Callout title="No files available!" style={{marginTop: 80, boxShadow: "1px 1px 2px #ccc"}}>
                We could not find any files for your account and software.  
                Please contact Carestream Dental support to resolve the issue.
            </Callout>
        );
    }
    
    return (
        <div className="files">
            <Instrucstions />
            <div>
                {files.map(f => <File key={f.id} {...f} />)}
            </div>
        </div>
    );
});


export default Content;
