import React from "react";
import { NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import useLocale from "../shared/useLocale";
import routePaths from ".";

const LoginRequired: React.FC = () => {

  const { buildLocaleRoute } = useLocale();
  const route = buildLocaleRoute(routePaths.locale);

  return (
    <NonIdealState 
      className="hcentered" 
      icon={IconNames.SHIELD}   
      title="Login Required" 
    >
      <a href={route}>Go to Login page</a>
    </NonIdealState>);
  }

export default LoginRequired;
