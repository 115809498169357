           
import React, { useState, useCallback, useLayoutEffect } from "react";
import { FormGroup, InputGroup, Intent } from "@blueprintjs/core";


export interface ITextFieldState {
    value: string;
    isValid: boolean;

}


export interface ITextFieldProps {
    label: string
    name: string;
    initialValue: string;
    forceUppercase?: boolean;
    onChange: (state: ITextFieldState) => void;
    getErrorMessage: (value: string) => string | undefined;
}


const TextField: React.FC<ITextFieldProps> = ({ label, name, forceUppercase = false, onChange: notifyOnChange, getErrorMessage }) => {
    const [value, setValue] = useState("");
    const [error, setError] = useState<string>();
    const [touched, setTouched] = useState(false);
    const [cursorPositionSetter, setCursorPositionSetter] = useState<(() => void) | null>(null);
    
    useLayoutEffect(() => {
        if (cursorPositionSetter) {            
            cursorPositionSetter();
            setCursorPositionSetter(null);
        }
    }, [cursorPositionSetter])

    const onChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        e.persist();
        const input = e.currentTarget;
        let newValue = input.value;
        if (forceUppercase) {    
            newValue = newValue.toUpperCase();
            // Save cursor position so we can restore it after state change.
            const { selectionStart: start, selectionEnd: end } = input; 
            setCursorPositionSetter(() => () => input.setSelectionRange(start!,end!));
        }
            
        setValue(newValue);
        const errorMsg = getErrorMessage(newValue);
        setError(errorMsg);
        notifyOnChange({value: newValue, isValid: !errorMsg});
    }, [forceUppercase, getErrorMessage, notifyOnChange]);

    const onBlur = useCallback(() => {
        setTouched(true);
        const errorMsg = getErrorMessage(value);
        setError(errorMsg);
        notifyOnChange({value, isValid: !errorMsg});
    }, [getErrorMessage, notifyOnChange, value]);

    return (
        <FormGroup 
            label={label}
            helperText={touched && error ? error : <div>&nbsp;</div>}
            intent={!!error ? Intent.DANGER : Intent.NONE}
        >
            <InputGroup         
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}                
            />
        </FormGroup>
    );

}

export default TextField;
