import React, {  } from "react";
import { Text, Classes } from "@blueprintjs/core";
import useLocale from "../shared/useLocale";



const Intro = () => {
    const { isUsLocale } = useLocale();

    // Apr 9 2021: Per request from Nina both US and UK now show the same message
    // if (isUsLocale) {
    //     return (
    //         <Text className={Classes.RUNNING_TEXT}>
    //             <p>Type your Carestream Dental Customer ID and zip code to access available downloads.</p>
    //             <p>
    //                 Your Customer ID is between 6 and 10 numbers and is located on your billing statement and by selecting
    //                 <span> </span>                    
    //                 <code>Help&nbsp;&gt;&nbsp;About&nbsp;&gt;&nbsp;Show&nbsp;License&nbsp;Details</code> in your practice management software.
    //             </p>
    //         </Text>
    //     );
    // }

    return (
        <Text className={Classes.RUNNING_TEXT}>
            <p>Type your Carestream Dental Customer ID and {isUsLocale ? "zip code" : "postcode"} to access available downloads.</p>
            <p>Your Customer ID is between 6 and 10 digits and is located on the Support Schedules, Annual Contract Renewals, and any Invoice.</p>
        </Text>
    );
}



export default Intro;