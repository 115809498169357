import { useParams, generatePath } from "react-router";
import { useCallback, useMemo } from 'react';


export function normalizeLocaleString(locale: string) {
  // Note: Some browsers (i.e. Safari) returns the string as lowercase.
  console.assert(locale && locale.length === 5);
  return locale.substr(0, 3).toLowerCase() + locale.substr(3).toUpperCase();
}


export function getBrowserLocale() {
  const locale = navigator.language;
  return normalizeLocaleString(locale);
}


export const isLocaleUS = (locale: string) => locale.toLowerCase().endsWith("-us");
//export const isLocaleUK = (locale: string) => locale.toLowerCase().endsWith("-gb");


interface ILocaleSettings {
  phone: {
    allowedChars: RegExp;
    allowedDigitCounts: number[];
  },

  postCode: {
    regex: RegExp;
    maxLen: number;
    desc: string;
  }
}


const localeSettings: { [locale: string]: ILocaleSettings } = {
  us: {
    phone: {
      allowedChars: /^[0-9() -]*$/,
      allowedDigitCounts: [7, 10]
    },
    postCode: {
      regex: /^[0-9]{5}([ -]?[0-9]{4})?$/,
      maxLen: 10,
      desc: "Must contain five or nine digits"
    }
  },

  ca: {
    phone: {
      allowedChars: /^[0-9() -]*$/,
      allowedDigitCounts: [7, 10]
    },
    postCode: {
      // https://en.wikipedia.org/wiki/Postal_codes_in_Canada#:~:text=A%20Canadian%20postal%20code%20is,the%20third%20and%20fourth%20characters.
      regex: /^[a-zA-Z][0-9][a-zA-Z] [0-9][a-zA-Z][0-9]$/,
      maxLen: 7,
      desc: "Must be in the A1A 1A1 format"
    }
  },

  other: {
    phone: {
      allowedChars: /^[0-9 -]*$/,
      allowedDigitCounts: [11]
    },
    postCode: {
      // Originally based on: https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Validation, but allows lowercase
      // Modified using Regex from R4+: [A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}
      regex: /^[A-Za-z]{1,2}[0-9rR][0-9A-Za-z]? ?[0-9][A-Za-z]{2}$/,     
      maxLen: 8,
      desc: "Not recognized as a valid post code"
    }
  }
}


function getLocaleSettions(locale: string) {
  const region = locale.slice(-2);
  console.assert(!!region);
  return localeSettings[region.toLowerCase()] || localeSettings.other;
}



export default function useLocale() {
  const { locale }  = useParams<{locale: string}>();  
  //const locale = normalizeLocaleString(routeParams.locale);
  //console.log("Route params: ", routeParams.locale)

  const buildLocaleRoute = useCallback((routeWithLocalePlaceholder: string) => 
    generatePath(routeWithLocalePlaceholder, {locale}), [locale]);
  
  if (!locale) {
      throw new Error("Unexpected! No locale is in router param!");
  }
  
  const settings = useMemo(() => getLocaleSettions(locale), [locale]);
  const isUsLocale = isLocaleUS(locale);

  return { locale, settings, isUsLocale, buildLocaleRoute };
}