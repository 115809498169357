import React from 'react';
import { Route, Switch, Redirect } from "react-router";
import useLocale from "../shared/useLocale";
import routes, { getFallbackLocale } from "../routing";
import PrivateRoute from "../routing/PrivateRoute";
import Home from "../Home";
import Files from "../Files";
import routePaths from '../routing';


const LocaleRoutes = () => {
  const { locale } = useLocale();
  // Save to session storage so that HTTP API can send the locale to server
  sessionStorage.setItem("locale", locale); 
  
  return (
    <Switch>
      <Route path={routes.home} component={Home} />
      <PrivateRoute path={routes.files} component={Files} />
      <Route component={Home} />      
    </Switch>
  );
}


const UnknownLocale = () => {
  const { locale } = useLocale();
  // Map the current locale into a supported locale
  const fallbackLocale = getFallbackLocale(locale);
  return <Redirect to={fallbackLocale} />;
}


const DefaultLocale = () => {
  console.log("No locale specified. Switching to default locale route: " + routePaths.fallbackLocale);
  return <Redirect to={routePaths.fallbackLocale} />;
}


const AppRoutes = () => {  
  return (
    <Switch>
      <Route path={routePaths.locale} component={LocaleRoutes} sensitive />
      <Route path={routePaths.unknownLocale} component={UnknownLocale} />
      <Route component={DefaultLocale} />
    </Switch>
  );  
}

export default AppRoutes;
